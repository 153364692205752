@import "./variables.scss";
@media (min-width: 600px) {
    .menu {
        user-select: none;
        max-width: 100%;
        width: 50vw;
        min-width: 300px;
        height: 40vw;
        min-height: 400px;
        margin-top: 1vh;
        margin-left: 47vw;
        border-radius: 2vw;
        position: fixed;
        border: 1px solid black;
        top: 0;
        left: 100vw;
        padding: 2.5vw;
        z-index: 3;
        background: $red;
        display: flex;
        flex-direction: column;
        .close {
            flex: 2;
        }
        .header-h1 {
            flex: 2;
        }
        .links {
            flex: 10;
            flex-direction: column;
            display: flex;
            a {
                color: black;
                text-decoration: none;
            }
            a:hover {
                color: white;
                text-decoration: none;
            }
            // a::after {
            //     content: '';
            //     left: 0;
            //     bottom: 0;
            //     display: block;
            //     width: 0;
            //     height: 1px;
            //     background: black;
            //     transition: 0.8s;
            // }
            // a:hover::after {
            //     width: 50%;
            //     background-color: black;
            // }
            justify-content: space-evenly;
        }
        &.open {
            left: 0vw;
            display: flex;
        }
        .close {
            cursor: pointer;
        }
        .close:hover {
            cursor: pointer;
            color: white;
        }
    }
}

@media (max-width: 600px) {
    .menu {
        // max-width: 100%;
        width: 100vw;
        height: 100vh;
        // margin-top: 1vh;
        // margin-left: 28vw;
        // border-radius: 2vw;
        position: fixed;
        border: 1px solid black;
        top: 0;
        left: 100vw;
        padding: 2.5vw;
        z-index: 3;
        background: $red;
        display: flex;
        flex-direction: column;
        .close {
            flex: 2;
        }
        .header-h1 {
            flex: 2;
        }
        .links {
            flex: 10;
            flex-direction: column;
            display: flex;
            a {
                color: black;
                text-decoration: none;
            }
            a:hover {
                color: white;
                text-decoration: none;
            }
            // a::after {
            //     content: '';
            //     left: 0;
            //     bottom: 0;
            //     display: block;
            //     width: 0;
            //     height: 1px;
            //     background: black;
            //     transition: 0.8s;
            // }
            // a:hover::after {
            //     width: 50%;
            //     background-color: black;
            // }
            justify-content: space-evenly;
        }
        &.open {
            left: 0;
            margin-right: 80vw;
            display: flex;
        }
        .close {
            cursor: pointer;
        }
        .close:hover {
            cursor: pointer;
            color: white;
        }
        user-select: none;
    }
}