@import "./variables.scss";
.App {
    background-color: black;
    body {
        background-color: black;
    }
    .menu-svg {
        cursor: pointer;
        opacity: 1;
    }
    .menu-svg:hover {
        cursor: pointer;
        opacity: .8;
    }
    .header {
        position: fixed;
        user-select: none;
        top: 0;
        display: flex;
        margin-top: 0;
        z-index: 2;
        justify-content: space-between;
        border-radius: 0 0 20px 20px;
        background-color: black;
        @media (max-width: 465px) {
            .header-h1 {
                padding-left: 2.5vw;
                height: 60px;
                display: flex;
                align-items: center;
                color: white;
                font-size: 15px;
            }  
        }
        @media (min-width:465px){
            .header-h1 {
                padding-left: 2.5vw;
                height: 60px;
                display: flex;
                align-items: center;
                color: white;
                font-size: 22px;
            }
        }
        .right {
            position: fixed;
            right: 0;
        }
        .navbar {
            display: flex;
            justify-content: flex-end;
            padding-right: 5vw;
            align-items: center;
            width: 40vw;
            height: 60px;
            color: white;
            font-size: 1vw;
        }
    }
    .wrap {
        height: 45px;
        background-color: black;
    }
    @media (min-width: 670px) {
        .wrap {
            height: 57px;
        }
    }
    .first {
        background-color: $red;
        // padding-top: 2vh;
    }
    .first-block {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .first-block-left {
            width: 100%;
        }
        @media (min-width: 670px) {
            .first-block-h1-1 {
//                 width: calc(95vw - 45px);
                display: flex;
                // padding-left: 3vw;
                font-size: 55px;
            }
            .first-block-h1-2 {
//                 width: calc(95vw - 45px);
                display: flex;
                // padding-left: 3vw;
                font-size: 55px;
            }
        }
        @media (max-width:670px) {
            .first-block-h1-1 {
                width: 80vw;
                display: flex;
                padding-left: 0vw;
                font-size: 40px;
            }
            .first-block-h1-2 {
                width: 80vw;
                display: flex;
                padding-left: 0vw;
                font-size: 40px;
            }
        }
        .first-block-right {
            display: flex;
            flex-direction: column;
            .first-block-consult-1 {
                margin-top: 3vh;
                // margin-left: 7vw;
                // margin-bottom: 5vh;
                width: 150px;
                height: 150px;
                background: black;
                -moz-border-radius: 100px;
                -webkit-border-radius: 100px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                text-align: center;
                align-self: center;
                justify-content: space-evenly;
                flex-direction: row;
                .first-block-consult-p {
                    color: white;
                    font-size: min(2.4vw, 15px);
                    width: fit-content;
                }
            }
            .first-block-consult {
                margin-top: 3vh;
                // margin-left: 7vw;
                // margin-bottom: 5vh;
                width: 150px;
                height: 150px;
                background: black;
                -moz-border-radius: 100px;
                -webkit-border-radius: 100px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                text-align: center;
                align-self: center;
                justify-content: space-evenly;
                flex-direction: row;
                .first-block-consult-p {
                    color: white;
                    font-size: min(2.4vw, 15px);
                    width: fit-content;
                }
            }
            .first-block-consult:hover {
                background: white;
                .first-block-consult-p {
                    color: black;
                    // font-size: min(2vh, 1.5vw);
                }
            }
            .first-block-consult-pp {
                text-align: right;
//                 margin-right: 3vw;
//                 margin-top: 3vw;
                font-size: min(3vw, 14px);
            }
        }
    }
    // @media (max-width: 670px) {
    .first-block-img {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        background-image: url("../images/img-1.jpg");
        background-size: cover;
        background-position: center center;
        border-radius: 0 0 20px 20px;
        // margin-bottom: 2vh;
        max-height: 60vh;
        @media (min-width: 670px) {
            min-height: 800px;
        }
        .img-1 {
            width: 100vw;
            border-radius: 20px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0vh;
            border: 2px solid black;
            visibility: hidden;
        }
    }
    .first {
        flex-direction: column-reverse;
        display: flex;
        padding-top: 0;
        margin-bottom: 2.5vw;
        .first-block {
            flex-direction: column;
            display: flex;
            padding: 1vw 5vw;
            .first-block-right {
                width: 85vw;
                flex-direction: row;
                justify-content: space-between;
                display: flex;
                margin-bottom: 2.5vw;
                // margin-bottom: 2.5vh;
                align-items: center;
                .first-block-consult {
                    margin-left: 0em;
                }
            }
        }
    }
    .first-block-consult-pp {
        width: calc(85vw - 175px);
        // margin-top: 10vh;
        text-align: right;
    }
    // }
    @media (min-width: 670px) {
        .first-block-consult-pp {
            width: calc(85vw - 215px);
            // margin-top: 10vh;
            text-align: right;
            max-width: 500px;
        }
        .first-block-right {
            width: calc(85vw - 40px);
        }
    }
    .second-block {
        padding: 0 5vw;
        background-color: white;
        display: flex;
        flex-direction: column;
        .sec-block {
            display: flex;
            justify-content: center;
        }
        .sec-block-h {
            margin-top: 65px;
            display: flex;
            align-self: center;
            // justify-content: space-around;
            width: fit-content;
            h1 {
                font-size: max(35px, 3vw);
            }
        }
        .sec-left {
            margin-top: 5vh;
            width: 50vw;
            .second-block-name {
                margin-left: 10vw;
                font-size: 2vw;
            }
            .sec-left-img {
                width: 30vw;
                margin-left: 10vw;
                margin-top: 5vh;
            }
        }
        .sec-right {
            width: 50vw;
        }
        @media (min-width: 670px) {
            .sec-right-1 {
                margin-top: 5vh;
                margin-left: 2vw;
                // margin-bottom: 5vh;
                width: 25vw;
                height: 25vw;
                background: $light-gray;
                -moz-border-radius: 12.5vw;
                -webkit-border-radius: 12.5vw;
                border-radius: 12.5vw;
                text-align: center;
                justify-content: center;
                display: flex;
                align-items: center;
                .sec-right-p {
                    padding-right: 2.2vw;
                    padding-left: 2.2vw;
                    padding-bottom: 1vw;
                    font-size: 1.1vw;
                }
                .sec-right-h3 {
                    font-size: 2vw;
                }
            }
            .sec-right-2 {
                margin-top: -5vh;
                margin-left: 20vw;
                // margin-bottom: 5vh;
                width: 25vw;
                height: 25vw;
                background: $light-gray;
                -moz-border-radius: 12.5vw;
                -webkit-border-radius: 12.5vw;
                border-radius: 12.5vw;
                text-align: center;
                justify-content: center;
                display: flex;
                align-items: center;
                .sec-right-p {
                    padding-right: 2.2vw;
                    padding-left: 2.2vw;
                    font-size: 1.1vw;
                }
                .sec-right-h3 {
                    font-size: 2vw;
                }
            }
            .sec-right-3 {
                margin-top: -5vh;
                margin-left: 2vw;
                // margin-bottom: 5vh;
                width: 25vw;
                height: 25vw;
                background: $light-gray;
                -moz-border-radius: 12.5vw;
                -webkit-border-radius: 12.5vw;
                border-radius: 12.5vw;
                text-align: center;
                justify-content: center;
                display: flex;
                align-items: center;
                .sec-right-p {
                    padding-right: 2.2vw;
                    padding-left: 2.2vw;
                    font-size: 1.1vw;
                }
                .sec-right-h3 {
                    font-size: 2vw;
                }
            }
        }
        @media (min-width: 900px) {
            .sec-right-1 {
                margin-top: 15vh;
                margin-left: 2vw;
                // margin-bottom: 5vh;
                width: 25vw;
                height: 25vw;
                background: $light-gray;
                -moz-border-radius: 12.5vw;
                -webkit-border-radius: 12.5vw;
                border-radius: 12.5vw;
                text-align: center;
                justify-content: center;
                display: flex;
                align-items: center;
                .sec-right-p {
                    padding-right: 2.2vw;
                    padding-left: 2.2vw;
                    padding-bottom: 1vw;
                    font-size: 1.2vw;
                }
                .sec-right-h3 {
                    font-size: 2vw;
                }
            }
            .sec-right-2 {
                margin-top: -5vw;
                margin-left: 20vw;
                // margin-bottom: 5vh;
                width: 25vw;
                height: 25vw;
                background: $light-gray;
                -moz-border-radius: 12.5vw;
                -webkit-border-radius: 12.5vw;
                border-radius: 12.5vw;
                text-align: center;
                justify-content: center;
                display: flex;
                align-items: center;
                .sec-right-p {
                    padding-right: 2.2vw;
                    padding-left: 2.2vw;
                    padding-bottom: 1vw;
                    font-size: 1.2vw;
                }
                .sec-right-h3 {
                    font-size: 2vw;
                }
            }
            .sec-right-3 {
                margin-top: -5vw;
                margin-left: 2vw;
                // margin-bottom: 5vh;
                width: 25vw;
                height: 25vw;
                background: $light-gray;
                -moz-border-radius: 12.5vw;
                -webkit-border-radius: 12.5vw;
                border-radius: 12.5vw;
                text-align: center;
                justify-content: center;
                display: flex;
                align-items: center;
                .sec-right-p {
                    padding-right: 2.2vw;
                    padding-left: 2.2vw;
                    padding-bottom: 1vw;
                    font-size: 1.2vw;
                }
                .sec-right-h3 {
                    font-size: 2vw;
                }
            }
        }
        @media (max-width: 670px) {
            .sec-block {
                display: flex;
                flex-direction: column;
                background-image: url("../images/img-2.png");
                background-size: cover;
                background-position: center center;
                border-radius: 22px;
                // border: 1px solid black;
                .sec-left {
                    padding: 1em;
                    backdrop-filter: blur(5px);
                    background: rgba(0, 0, 0, 0.115);
                    width: 100%;
                    // border-radius: 22px 22px 0 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 400px;
                    color: white;
                    border: white 1px solid;
                    h2 {
                        width: fit-content;
                        margin: 0;
                        font-size: 30px;
                    }
                    a {
                        display: none;
                    }
                }
                .sec-right {
                    border: white 1px solid;
                    padding: 1em;
                    backdrop-filter: blur(5px);
                    background: rgba(0, 0, 0, 0.115);
                    width: 100%;
                    color: white;
                    border-radius: 0 0 22px 22px;
                    .sec-right-1 {
                        // margin-top: 5vh;
                        // margin-left: 2vw;
                        // margin-bottom: 5vh;
                        // width: 25vw;
                        // height: 25vw;
                        // background: $light-gray 0.5;
                        // -moz-border-radius: 12.5vw;
                        // -webkit-border-radius: 12.5vw;
                        // border-radius: 0 12.5vw;
                        text-align: center;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        .sec-right-p {
                            padding-right: 2.2vw;
                            padding-left: 2.2vw;
                            padding-bottom: 1vw;
                            margin-top: 1.5vw;
                            font-size: 14px;
                        }
                        @media (min-width: 800px) {
                            .sec-right-h3 {
                                font-size: 1.8vw;
                            }
                        }
                        @media (max-width: 800px) {
                            .sec-right-h3 {
                                font-size: 4vw;
                            }
                        }
                    }
                    .sec-right-2 {
                        // margin-top: -3vh;
                        // margin-left: 20vw;
                        // margin-bottom: 5vh;
                        // width: 25vw;
                        // height: 25vw;
                        // background: $light-gray;
                        // -moz-border-radius: 12.5vw;
                        // -webkit-border-radius: 12.5vw;
                        // border-radius: 12.5vw;
                        text-align: center;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        .sec-right-p {
                            padding-right: 2.2vw;
                            padding-left: 2.2vw;
                            padding-bottom: 1vw;
                            margin-top: 1.5vw;
                            font-size: 14px;
                        }
                        @media (min-width: 800px) {
                            .sec-right-h3 {
                                font-size: 1.8vw;
                            }
                        }
                        @media (max-width: 800px) {
                            .sec-right-h3 {
                                font-size: 4vw;
                            }
                        }
                    }
                    .sec-right-3 {
                        // margin-top: -3vh;
                        // margin-left: 2vw;
                        // margin-bottom: 5vh;
                        // width: 25vw;
                        // height: 25vw;
                        // background: $light-gray;
                        // -moz-border-radius: 12.5vw;
                        // -webkit-border-radius: 12.5vw;
                        // border-radius: 12.5vw;
                        text-align: center;
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        .sec-right-p {
                            padding-right: 2.2vw;
                            padding-left: 2.2vw;
                            padding-bottom: 1vw;
                            margin-top: 1.5vw;
                            font-size: 14px;
                        }
                        @media (min-width: 800px) {
                            .sec-right-h3 {
                                font-size: 1.8vw;
                            }
                        }
                        @media (max-width: 800px) {
                            .sec-right-h3 {
                                font-size: 4vw;
                            }
                        }
                    }
                }
            }
        }
        .adv-sud-block {
            text-align: justify;
            padding-bottom: 2vw;
            @media (min-width: 600px) {
                .adv-skills-txt {
                    font-weight: 400;
                    font-size: 1.5vw;
                    margin-top: 1.5vw;
                }
            }
            @media (max-width:600px) {
                .adv-skills-txt {
                    font-weight: 400;
                    font-size: 3vw;
                    margin-top: 1.5vw;
                }
            }
            .header-2-h1 {
                font-size: max(2.35vw, 22px);
                margin-top: 3vw;
                margin-bottom: 5vw;
            }
            @media (max-width: 600px) {
                .adv-sud-txt {
                    font-size: max(2.1vw, 17px);
                    font-weight: 500;
                    margin-top: 3.5vw;
                }
            }
            @media (min-width: 600px) {
                .adv-sud-txt {
                    font-size: 1.5vw;
                    font-weight: 500;
                    margin-top: 2vw;
                }
            }
            .adv-sud-h2 {
                font-size: max(2vw, 20px);
                margin-bottom: 2vw;
                margin-top: 3vw;
            }
            @media (max-width: 600px) {
                .adv-sud-skills {
                    width: max-content;
                    display: flex;
                    width: 86vw;
                    // height: 200px;
                    overflow-y: hidden;
                    overflow-x: auto;
                    justify-content: space-between;
                    margin-bottom: 2vw;
                    margin-top: 2vw;
                    .adv-sud-skill-1 {
                        min-width: 60vw;
                        // min-width: 200px;
                        margin-right: 4vw;
                        padding: 1vw 2vw 1vw 2vw;
                        border-radius: 1vw;
                        background-color: #F6F6F6;
                        border: 1px solid #F6F6F6;
                        .adv-sud-skill-head {
                            display: flex;
                            font-size: 5vw;
                            align-items: center;
                            justify-content: left;
                            .adv-sud-skill-circle {
                                width: 4vw;
                                height: 4vw;
                                border: 1px solid black;
                                border-radius: 2vw;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                text-align: center;
                                padding-bottom: 0.3vw;
                                margin-right: 2vw;
                                .adv-sud-skill-circle-p {
                                    width: 20vw;
                                    font-size: 3vw;
                                }
                            }
                            .adv-sud-skill-1-h2 {
                                font-weight: 500;
                                font-size: 3.5vw;
                            }
                        }
                    }
                    .adv-sud-skill-2 {
                        min-width: 60vw;
                        ;
                        margin-right: 4vw;
                        padding: 1vw 2vw 1vw 2vw;
                        border-radius: 1vw;
                        background-color: #F6F6F6;
                        border: 1px solid #F6F6F6;
                        .adv-sud-skill-head {
                            display: flex;
                            font-size: 1.9vw;
                            align-items: center;
                            justify-content: left;
                            .adv-sud-skill-circle {
                                width: 4vw;
                                height: 4vw;
                                border: 1px solid black;
                                border-radius: 2vw;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                text-align: center;
                                padding-bottom: 0.3vw;
                                margin-right: 2vw;
                            }
                            .adv-sud-skill-circle-p {
                                width: 20vw;
                                font-size: 3vw;
                            }
                            .adv-sud-skill-2-h2 {
                                font-weight: 500;
                                font-size: 3.5vw;
                            }
                        }
                    }
                    .adv-sud-skill-4 {
                        min-width: 60vw;
                        padding: 1vw 2vw 1vw 2vw;
                        border-radius: 1vw;
                        background-color: #F6F6F6;
                        border: 1px solid #F6F6F6;
                        .adv-sud-skill-head {
                            display: flex;
                            font-size: 1.9vw;
                            align-items: center;
                            justify-content: left;
                            .adv-sud-skill-circle {
                                width: 4vw;
                                height: 4vw;
                                border: 1px solid black;
                                border-radius: 2vw;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                text-align: center;
                                padding-bottom: 0.3vw;
                                margin-right: 2vw;
                                .adv-sud-skill-circle-p {
                                    width: 20vw;
                                    font-size: 3vw;
                                }
                            }
                            .adv-sud-skill-4-h2 {
                                font-weight: 500;
                                font-size: 3.5vw;
                            }
                        }
                    }
                    .adv-sud-skill-3 {
                        min-width: 60vw;
                        ;
                        padding: 1vw 2vw 1vw 2vw;
                        border-radius: 1vw;
                        margin-right: 4vw;
                        background-color: #F6F6F6;
                        border: 1px solid #F6F6F6;
                        .adv-sud-skill-head {
                            display: flex;
                            font-size: 1.9vw;
                            align-items: center;
                            justify-content: left;
                            .adv-sud-skill-circle {
                                width: 4vw;
                                height: 4vw;
                                border: 1px solid black;
                                border-radius: 2vw;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                text-align: center;
                                padding-bottom: 0.3vw;
                                margin-right: 2vw;
                                .adv-sud-skill-circle-p {
                                    width: 20vw;
                                    font-size: 3vw;
                                }
                            }
                            .adv-sud-skill-3-h2 {
                                font-weight: 500;
                                font-size: 3.5vw;
                            }
                        }
                    }
                }
            }
            @media (min-width: 600px) {
                .adv-sud-skills {
                    /* плашка-бегунок и фон */
                    scrollbar-color: #999 #333;
                }
                .adv-sud-skills::-webkit-scrollbar {
                    width: 10px;
                    /* в основном для вертикальных полос прокрутки */
                    height: 10px;
                    /* в основном для горизонтальных полос прокрутки */
                }
                .adv-sud-skills::-webkit-scrollbar-thumb {
                    /* плашка-бегунок */
                    background: #c2c2c2;
                    border-radius: 10px;
                }
                .adv-sud-skills::-webkit-scrollbar-track {
                    /* фон */
                    background: #ffffff;
                }
                .adv-sud-skills {
                    margin-top: 2vw;
                    width: max-content;
                    display: flex;
                    width: 86vw;
                    // height: 200px;
                    overflow-y: hidden;
                    overflow-x: auto;
                    white-space: wrap;
                    justify-content: space-between;
                    margin-bottom: 2vw;
                    padding-bottom: 2vh;
                    .adv-sud-skill-1 {
                        // max-height: auto;
                        min-width: 35vw;
                        margin-right: 4vw;
                        padding: 1vw 2vw 1vw 2vw;
                        border-radius: 1vw;
                        background-color: #F6F6F6;
                        border: 1px solid #F6F6F6;
                        .adv-sud-skill-head {
                            display: flex;
                            font-size: 1.9vw;
                            align-items: center;
                            justify-content: left;
                            .adv-sud-skill-circle {
                                width: 3vw;
                                height: 3vw;
                                border: 1px solid black;
                                border-radius: 1.5vw;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                text-align: center;
                                padding-bottom: 0.3vw;
                                margin-right: 2vw;
                                .adv-sud-skill-circle-p {
                                    width: 20vw;
                                    font-size: 2vw;
                                }
                            }
                            .adv-sud-skill-1-h2 {
                                font-weight: 500;
                                font-size: 1.9vw;
                            }
                        }
                    }
                    .adv-sud-skill-2 {
                        min-width: 35vw;
                        margin-right: 4vw;
                        padding: 1vw 2vw 1vw 2vw;
                        border-radius: 1vw;
                        background-color: #F6F6F6;
                        border: 1px solid #F6F6F6;
                        .adv-sud-skill-head {
                            display: flex;
                            font-size: 1.9vw;
                            align-items: center;
                            justify-content: left;
                            .adv-sud-skill-circle {
                                width: 3vw;
                                height: 3vw;
                                border: 1px solid black;
                                border-radius: 1.5vw;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                text-align: center;
                                padding-bottom: 0.3vw;
                                margin-right: 2vw;
                            }
                            .adv-sud-skill-circle-p {
                                width: 20vw;
                                font-size: 2vw;
                            }
                            .adv-sud-skill-2-h2 {
                                font-weight: 500;
                                font-size: 1.9vw;
                            }
                        }
                    }
                    .adv-sud-skill-3 {
                        min-width: 35vw;
                        margin-right: 4vw;
                        padding: 1vw 2vw 1vw 2vw;
                        border-radius: 1vw;
                        background-color: #F6F6F6;
                        border: 1px solid #F6F6F6;
                        .adv-sud-skill-head {
                            display: flex;
                            font-size: 1.9vw;
                            align-items: center;
                            justify-content: left;
                            .adv-sud-skill-circle {
                                width: 3vw;
                                height: 3vw;
                                border: 1px solid black;
                                border-radius: 1.5vw;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                text-align: center;
                                padding-bottom: 0.3vw;
                                margin-right: 2vw;
                            }
                            .adv-sud-skill-circle-p {
                                width: 20vw;
                                font-size: 2vw;
                            }
                            .adv-sud-skill-3-h2 {
                                font-weight: 500;
                                font-size: 1.9vw;
                            }
                        }
                    }
                    .adv-sud-skill-4 {
                        min-width: 35vw;
                        padding: 1vw 2vw 1vw 2vw;
                        border-radius: 1vw;
                        background-color: #F6F6F6;
                        border: 1px solid #F6F6F6;
                        .adv-sud-skill-head {
                            display: flex;
                            font-size: 1.9vw;
                            align-items: center;
                            justify-content: left;
                            .adv-sud-skill-circle {
                                width: 3vw;
                                height: 3vw;
                                border: 1px solid black;
                                border-radius: 1.5vw;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                text-align: center;
                                padding-bottom: 0.3vw;
                                margin-right: 2vw;
                                .adv-sud-skill-circle-p {
                                    width: 20vw;
                                    font-size: 2vw;
                                }
                            }
                            .adv-sud-skill-4-h2 {
                                font-weight: 500;
                                font-size: 1.9vw;
                            }
                        }
                    }
                }
            }
        }
        .adv-ug-del-block {
            text-align: justify;
            padding-bottom: 2vw;
            @media (max-width: 600px) {
                .adv-ug-del-txt {
                    font-size: max(2.1vw, 17px);
                    font-weight: 500;
                    margin-top: 3.5vw;
                }
                .adv-ug-del-li {
                    margin-top: 0.5vw;
                    font-size: max(1.9vw, 14px);
                    font-weight: 400;
                }
            }
            @media (min-width: 600px) {
                .adv-ug-del-txt {
                    font-size: 1.5vw;
                    font-weight: 500;
                    margin-top: 2vw;
                }
                .adv-ug-del-li {
                    margin-top: 1vw;
                    font-size: 1.4vw;
                    font-weight: 400;
                }
            }
            .header-2-h1 {
                font-size: max(2.35vw, 22px);
                margin-top: 3vw;
                margin-bottom: 5vw;
            }
            .adv-ug-del-h2 {
                margin-top: 3vw;
                margin-bottom: 2vw;
                font-size: max(2.35vw, 22px);
            }
        }
        .sec-header-h {
            display: flex;
            justify-content: space-around;
            margin-top: 10vh;
            font-size: max(3vw, 35px);
        }
        .adv-ug-block {
            text-align: justify;
            padding-bottom: 2.5vw;
            @media (max-width: 600px) {
                .adv-ug-del-txt {
                    font-size: max(2.1vw, 17px);
                    font-weight: 500;
                    margin-top: 3.5vw;
                }
                .adv-ug-del-li {
                    margin-top: 0.5vw;
                    font-size: max(1.9vw, 14px);
                    font-weight: 400;
                }
            }
            @media (min-width: 600px) {
                .adv-ug-del-txt {
                    font-size: 1.5vw;
                    font-weight: 500;
                    margin-top: 2vw;
                }
                .adv-ug-del-li {
                    margin-top: 1vw;
                    font-size: 1.4vw;
                    font-weight: 400;
                }
            }
            .header-2-h1 {
                font-size: max(2.35vw, 22px);
                margin-top: 3vw;
                margin-bottom: 5vw;
            }
            .adv-ug-del-h2 {
                font-size: max(2vw, 20px);
                margin-bottom: 2vw;
                margin-top: 3vw;
            }
        }
        .adv-usl-block {
            text-align: justify;
            padding-bottom: 2vw;
            @media (max-width: 600px) {
                .adv-ug-del-txt {
                    font-size: max(2.1vw, 17px);
                    font-weight: 500;
                    margin-top: 3.5vw;
                }
                .adv-ug-del-li {
                    margin-top: 0.5vw;
                    font-size: max(1.9vw, 14px);
                    font-weight: 400;
                }
            }
            @media (min-width: 600px) {
                .adv-ug-del-txt {
                    font-size: 1.5vw;
                    font-weight: 500;
                    margin-top: 2vw;
                }
                .adv-ug-del-li {
                    margin-top: 1vw;
                    font-size: 1.4vw;
                    font-weight: 400;
                }
            }
            .header-2-h1 {
                font-size: max(2.35vw, 22px);
                margin-top: 3vw;
                margin-bottom: 5vw;
            }
            .adv-ug-del-h2 {
                font-size: max(2.35vw, 22px);
                margin-bottom: 2vw;
                margin-top: 3vw;
            }
        }
        .sec-navbar {
            margin-top: 5vh;
            overflow: hidden;
            .nav-wrap {
                display: flex;
                justify-content: space-between;
                // position: relative;
                // overflow-x: scroll;
                position: relative;
                // overflow: hidden;
                width: 100%;
                .adv-sud {
                    border-radius: 2vw 0 0 2vw;
                }
                .adv-usl {
                    border-radius: 0 2vw 2vw 0;
                }
            }
        }
    }
}