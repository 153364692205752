@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap'); // fonts
$Raleway: 'Raleway',
sans-serif;
// spasing
$spasing-md: 16px;
$spasing-lg: 32px;
//border radius
$borderRadius: 11px;
//header height
$headerHeight: 100px;
@mixin breakpoint($points) {
    @if $points==md {
        // 768 px
        @media (min-width: 48em) {
            @content;
        }
    }
}

$red: #E7251C;
$light-gray: #f5f5f5;