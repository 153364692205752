@import "./variables.scss";
.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em;
    .left {
        display: flex;
        flex-direction: column;
        p {
            font-size: min(3vw, 14px);
            color: white;
        }
    }
    .right {
        a {
            width: fit-content;
            color: #f6f6f6;
            font-size: min(3vw, 14px);
            color: white;
        }
        a::after {
            content: '';
            left: 0;
            bottom: 0;
            display: block;
            width: 0;
            height: 1px;
            background: #ffffff;
            transition: 0.8s;
        }
        a:hover::after {
            width: 100%;
            background-color: #f6f6f6;
        }
    }
}