    @import "./variables.scss";
    .contacts {
        background: $red;
        padding: 1em 5vw;
        .heading {
            width: 100%;
            font-size: 50px;
            font-weight: 400;
            padding-bottom: 0.15em;
            border-bottom: 2.5px solid black;
        }
        .content {
            width: 100%;
            display: flex;
            flex-direction: row;
            .left {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-top: 1em;
                margin-bottom: 0.5em;
                .textme {
                    flex: 3;
                    font-size: 14px;
                    font-weight: 300;
                    .title {
                        font-size: 25px;
                        font-weight: 500;
                    }
                }
                .name {
                    margin-top: 1em;
                    font-size: 25px;
                    font-weight: 500;
                }
            }
            .right {
                flex: 1;
                padding-top: 1em;
                .c-list {
                    .c-elem {
                        height: 35px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        svg {
                            margin-right: 0.3em;
                            stroke: black;
                        }
                    }
                    a {
                        color: black;
                    }
                    a:hover {
                        color: white;
                        svg {
                            stroke: white;
                        }
                    }
                }
                .sm {
                    margin-top: 1em;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .sm-elem-a {
                        text-decoration: none;
                        color: black;
                    }
                    .sm-elem {
                        padding: 0.3em 1em;
                        border: 1px solid black;
                        border-radius: 22px;
                        font-weight: 300;
                        cursor: pointer;
                        display: flex;
                        font-size: 14px;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-right: 0.5em;
                    }
                    .sm-elem:hover {
                        color: white;
                        border: 1px solid white;
                        svg {
                            margin-left: 0.5em;
                            path {
                                stroke: white;
                            }
                        }
                        margin-right: 0em;
                        // margin-right: -0.5em;
                    }
                }
                @media (max-width: 730px) {
                    .sm {
                        justify-content: space-evenly;
                    }
                }
            }
            @media (max-width: 730px) {
                .right {
                    border-top: 2px solid black;
                }
            }
            @media (min-width: 730px) {
                .left {
                    margin-right: 1em;
                }
            }
        }
        @media (max-width: 730px) {
            .heading {
                font-size: 30px;
            }
            .content {
                flex-direction: column;
            }
        }
    }