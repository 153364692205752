@import "./variables.scss";
* {
    box-sizing: border-box;
    margin: 0;
    transition: 0.3s ease all;
}

body {
    width: 100%;
    background: black;
    font-family: $Raleway;
}

#root {
    height: 100%;
}

.block {
    width: 95vw;
    border-radius: 20px;
    margin-bottom: 2.5vw;
    margin-left: 2.5vw;
}

@media (max-width: 800px) {
    .block {
        width: 100vw;
        margin-left: 0;
    }
}

html {
    scroll-behavior: smooth;
}