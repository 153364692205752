@import "./variables.scss";
.cbr {
    border: 1px solid black;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: max(2vw, 12px);
    width: 24%;
    margin-right: 0.5em;
    padding-bottom: .5vw;
    text-align: center;
}

.adv-usl {
    margin-right: 0;
}

.cbr:hover {
    background-color: $red;
    color: white;
    border: 1px solid $red;
}

.chosen {
    background: $red;
    color: white;
    border: 1px solid $red;
}